.formGroup{
    width: 80%;
    margin: 0 auto;
}
.image{
    width: 20%;
}
.formRow{
    padding-top: 15px;
    margin-bottom: 16px;
    box-shadow: 0px 3px 8px 6px #d7d3d3;
}
.subFieldsDiv{
    display: flex;
    flex-direction:column;
    row-gap: 16;
}
.closeForm{
    display: flex;
    justify-content: flex-end;
    cursor:pointer;   
    width: 100%;
    margin-bottom: 10px;
}
.openForm{
    box-shadow: 5px 5px 10px #b7b6b6;    
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
}
.openForm span{
    display: flex;
    justify-content: space-between;
    cursor:move;   
}
.openForm svg{
    cursor:pointer; 
}
.hint{
    font-size: 11px;
    font-style: italic;
}
