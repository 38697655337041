@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Montserrat&family=Roboto:wght@100;300;400&display=swap');
*{
  font-family: 'Roboto Condensed', sans-serif;

}
a{
  color:#3DA2C2;
}
.logo {
  width: 50%;
  display: flex;
}
.link{
  cursor: pointer;
  color:#3DA2C2;
}

.error {
  color: red;
  text-align: center;
}
.textArea{
  height: 120px !important;
}
.actionButton{
  padding: 0 50px 0 !important;
}
.draggable-dynamic-form {
  transition: opacity 0.3s ease-in-out; /* Adjust the duration and timing function as needed */
}
.ant-layout-header {
  background-color: #fff;
  border-bottom:1px solid #0000001A;

}
.ant-layout-sider{
  border-right:1px solid #0000001A;
  height: 100vh;
}
.ant-menu-item .anticon, .ant-menu-title-content{
   font-size: 16px !important;
   font-family: 'Roboto Condensed', sans-serif;;
}
.ant-menu-item{
    border-bottom: 1px solid #ccc;
    border-radius: 0 !important;
}
.ant-menu-item:hover .ant-menu-title-content{
  color:#3DA2C2;
}
.ant-menu-item:hover svg path{
  fill:#3DA2C2;
}
.ant-breadcrumb{
  padding-left: 20px;
}
.ant-table-row-expand-icon{
  font-size: 23px;
}
.ant-table-row-expand-icon-expanded{
  font-size: 30px;
}
.logout{
   font-size: 18px;  
   cursor: pointer; 
}
.ant-btn-default{
  border-color: #3DA2C2;
  padding: 5px 30px 20px 20px;
  margin-bottom: 10px;
}
.ant-checkbox .ant-checkbox-input{
  opacity: 1 !important;
}
.expandIcon{
  cursor: pointer;
  font-size:16px;
   padding-right: 10px;
}