.authRow{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh
}

.logo {
  margin-bottom: 20px; 
}

.formContainer {
  text-align: center; 
}
.authFields{
    width: 100%;
    max-width: 400px;
    margin:0 10px;
  
}