.header{
    display: flex;
    align-items:center;
    justify-content: space-between;
}

.content {
    margin: 20px 16px;
    background-color:#fff;
    padding:10px 16px; 
}
